header {
  transition: 500ms;
  padding:15px;
}

header img {
  height: 100%;
  max-height: 50px;
}

html {
  box-sizing: border-box;
}

html, body, div#root {
  background:#f9f9f9;
  min-height: 100vh;
}

#loading__screen {
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(255,255,255,1);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  h5 {
    margin-right: 10px;
  }
}

#container_checkout {
  display: flex;
  align-items: stretch;
  min-height: 100vh;
  
  div#resume {
    transition: 500ms;
    padding: 1.5rem 2rem;
    width: 100%;
    max-width: 50vw;
    img {
      height: 100%;
      max-height: 50px;
    }
    div#product_section {
      margin-top: 1rem;
      padding: 1rem;
      > div, > table {
        background-color: rgba(255,255,255,.8);
        border-radius: 0.25rem;
      }
    }
  }
  #checkout_form {
    padding: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    form {
      width: 100%;
      max-width: 30vw;
    }
  }
  @media (max-width: 930px) {
    & {
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      div#resume {
        max-width: 100%;
        padding: 1rem;
        div#product_section {
          padding :0;
        }
      }
      #checkout_form {
        form {
          max-width: 100%;
        }
      }
    }
  }
}

div#checkout_content {
  background:#f9f9f9;
  margin-top:40px;
}

div#checkout_content div.container {
  padding:0px;
  background:#fff;
  min-height: 70vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-shadow: 0px 0px 10px rgba(0,0,0,.2);
}

div#error_product {
  color:#999;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1.5rem;
  min-height: 100vh;
  width: 100%;
  max-width: 100%;
}
div#error_product p {
  font-weight: 700;
  font-size:30px;
}

#product_desc {
  flex:1;
  width:100%;
  padding:0px;
  color:#444;
}

#no_others {
  padding: 10px 10px;
  height: 150px;
  background: #eee;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

#list_added {
  width: 100%;
  border-collapse: collapse;
}
#list_added thead tr th {
  border-bottom: 1px solid rgba(0,0,0,.5);
  font-size: 12px;
  padding: 6px 10px;
}
#list_added td {
  vertical-align: middle;
  font-size: 10px;
  padding: 6px 10px;
  border-bottom: 1px solid rgba(0,0,0,.3);
}
#list_added td div.d-flex div {
  width: 60px;
  height: 60px;
  margin-right: 4px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}

#list_others {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 10px;
  padding: 10px;
}

#list_others > div {
  display: flex;
  flex-direction: column;
  background: #fff;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0px 0px 5px rgba(0,0,0,.1);
}
#list_others div > div.img_other {
  width: 100%;
  height: 80px;
  background-size: contain;
  background-repeat: no-repeat;
  background-color: #fff;
  background-position: 50% 50%;
}
#list_others div > div.button_group {
  display: flex;
  button:nth-child(1) {
    color: #fff;
    font-size: 12px;
    text-transform: uppercase;
    padding: 4px 8px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    svg {
      margin-right: 4px;
    }
  }
  button:nth-child(2) {
    background: #fff;
    color: #0086f9;
    border-radius: 5px;
    border: 1px solid #0086f9;
    margin-left: 4px;
  }
}
#list_others div h5 {
  font-size: 12px;
  padding: 10px;
  margin: 0;
}

legend {
  font-size: 14px !important;
}

@media (max-width: 490px) {
  #list_added td div.d-flex div {
    display: none;
  }
  #list_others {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 380px) {
  #list_others {
    grid-template-columns:1fr;
  }
}

#product_desc h3 {
  font-weight: 600;
  font-size:24px;
}
#product_desc h4 {
  transition: 500ms;
  font-weight: 900;
}
#product_desc span.divider {
  display: block;
  margin:10px 0px;
  width:100%;
  height:1px;
  background:#eee;
}
#grid_desc {
  display:grid;
  grid-template-columns: 6fr 6fr;
}

#grid_desc > div:nth-child(1) {
  background: #f9f9f9;
  border-right:1px solid #eee;
  padding: 1.5rem;
}

.grid_input {
  display:grid;
  grid-template-columns: 1fr 1fr;
  grid-gap:10px;
}
.grid_input_4 {
  display:grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap:10px;
}
.grid_input_3 {
  display:grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap:10px;
}

#grid_desc form {
  padding:2rem;
}

@media screen and (max-width: 880px) {
  #grid_desc {
    grid-template-columns: 1fr;
  }
}
@media screen and (max-width: 770px) {
  .grid_input_4 {
    grid-template-columns: 1fr 1fr;
  }
}
@media screen and (max-width: 500px) {
  .grid_input {
    grid-template-columns: 1fr;
  }
  .grid_input_4 {
    grid-template-columns: 1fr;
  }
  .grid_input_3 {
    grid-template-columns: 1fr;
  }
  div#checkout_content div.container {
    padding:0px;
  }
  #product_desc {
    padding:0px;
  }
  header img {
    margin:0px auto;
    display:block;
  }
}
.item-media {
  background: #f9f9f9;
  padding: 1.5rem;
  &:nth-child(odd) {
    background: #eee;
  }
  & + .item-media {
    border-top: 1px solid #c0c0c0;
  }
}

.desc_media {
  max-width: 250px;
}
.media_content {
  width: 100%;
  padding: 0 1rem;
  .left {
    text-align: left;
  }
  .right {
    text-align: right;
  }
  p {
    font-size: 0.9rem;
    color: #444;
  }
}
.more_about {
  h5 {
    border-bottom: 2px solid #eee;
    padding: 1rem;
  }
}

.no_media {
  padding: 2rem 1rem;
  text-align: center;
  color: #999;
  background: #eee;
  border-radius: 0.5rem;
}

@media (max-width: 550px) {
  .item-media {
    flex-wrap: wrap;
    justify-content: center;
  }
  .more_about {
    h5 {
      text-align: center;
    }
  }
  .media_content {
    max-width: 100%;
    padding: 1rem 0;
    .right {
      text-align: left;
    }
  }
  .desc_media {
    max-width: 100%;
  }
}